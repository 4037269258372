.header {
  position: sticky;
  top: 0;
}

.header:global.navbar-dark {
  background-color:#282828 !important;
  color: white;
}

.mobile_menu {
  max-width: 24rem;
  /* background-image: url(/public/17545.jpg); */

}

.mobile_menu:global.variant-light {
  background-color: var(--bs-light);
}

.mobile_menu:global.variant-light.highlight-bg .nav-link.active {
  background-color: var(--bs-primary);
  color: var(--bs-contrast-primary);
  font-weight: bold;
}
.mobile_menu:global.variant-light.highlight-underline .nav-link.active {
  text-decoration: underline;
  font-weight: bold;
}

.mobile_menu:global.variant-dark {
  background-color: var(--bs-primary);
}

.mobile_menu:global.variant-dark .nav-link {
  color: var(--bs-contrast-dark);
}
.mobile_menu:global.variant-dark.highlight-bg .nav-link.active {
  background-color: var(--bs-white);
  color: var(--bs-primary);
  font-weight: bold;
}
.mobile_menu:global.variant-dark.highlight-underline .nav-link.active {
  text-decoration: underline;
  font-weight: bold;
}

.header:global.navbar .nav-link {
  padding-left: 2rem !important;
}

@media (max-width: 576px) {
  .tenant_logo {
    max-width: 11rem;
    max-height: 2.75rem;
  }
}

.header :global(.app_logo) {
  width: 130px;
  height: 36px;
  background-size: cover;
}

.logo_text {
  font-size: 1.3rem;
  margin-top:2rem
}
.logo_text div:last-child {
  margin-top: 0.2rem;
  font-size: 70%;
}

.accordion_header {
  cursor: pointer;
  padding: 0;
  padding-top: 1.5rem;
}

.accordion_header + :global.accordion-collapse {
  margin-top: 1rem;
}

.mobile_menu_item {
  margin-top: 1.5rem;
}
.mobile_menu_item:global.active {
  font-weight: bold;
  text-decoration: underline;
}
