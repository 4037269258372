.responsive_table {
  position: relative;
  border-radius: var(--bs-border-radius);
}
.responsive_table.no_status {
  border-bottom: solid 1px var(--bs-gray-400);
}

/*
@media screen and (max-width: 992px), screen and (max-height: 700px) {
  .responsive_table {
    box-shadow: none;
  }
}
*/

.header {
  color: var(--bs-primary);
  font-weight: bold;
  background-color: var(--primary-100);
  border-bottom: solid 0.15rem var(--bs-primary);
  border-top-left-radius: var(--bs-border-radius);
  border-top-right-radius: var(--bs-border-radius);
}
.header > div {
  padding: 0.375rem 0.75rem;
}
.header > div:global.sortable {
  cursor: pointer;
}

.footer {
  font-weight: bold;
  padding: 0.25rem 0.5rem;
  border-top: solid 0.15rem var(--bs-primary);
  border-left: solid 0.1rem var(--bs-gray-400);
  border-right: solid 0.1rem var(--bs-gray-400);
  border-bottom: solid 0.1rem var(--bs-gray-400);
  border-bottom-left-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

.row > * {
  padding: 0.375rem 0.75rem;
}

.last_row {
  top: calc(50% - 1rem);
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.scroll_area {
  /* border-left: solid 0.1rem var(--bs-gray-300); */
  /* border-right: solid 0.1rem var(--bs-gray-300); */
}

.norecords {
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 0;
  right: 0;
  z-index: 400;
}
.norecords div {
  z-index: 500;
  border-radius: var(--bs-border-radius);
}

.mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  border-top-left-radius: var(--bs-border-radius);
  border-top-right-radius: var(--bs-border-radius);
  z-index: 400;
}
.mask div {
  z-index: 500;
  border-radius: var(--bs-border-radius);
}
@media screen and (max-width: 992px), screen and (max-height: 700px) {
  .mask div {
    left: calc(50% - 9rem);
    position: fixed;
    width: 18rem;
    text-align: center;
    padding: 1rem;
    z-index: 500;
    bottom: 7rem;
  }
}
