.responsive_container {
  position: relative;
  overflow: auto;
}
.responsive_content {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.responsive_container.dimmed {
  overflow: hidden;
}

.dimmer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-width: 100%;
  height: 100%;
  z-index: 1050;
  background: #6c757d33;
}

@media screen and (max-width: 992px), screen and (max-height: 700px) {
  .responsive_container.with_media_query {
    position: unset;
  }
  .responsive_content.with_media_query {
    position: unset;
  }

  .spinner.with_media_query {
    display: none;
  }
}
