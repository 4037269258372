.container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  margin: 1rem;
  padding: 2rem;
  width: 400px;
  display: flex;
  flex-direction: column;
}

.tenant_logo {
  max-height: 4.5rem;
  margin: auto;
}
