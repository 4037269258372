.sidebar {
  border-radius: var(--bs-border-radius);
  position: relative;
}

.sidebar:global.variant-light {
  background-color: var(--bs-light);
}
.sidebar:global.variant-dark {
  background-color: var(--primary-100);
}

.sidebar:global.nav .nav-link {
  color: var(--bs-gray-700);
}
.sidebar:global.variant-light .nav-link i,
.sidebar:global.variant-dark .nav-link i {
  color: var(--accent);
}
/* .sidebar:global.nav .nav-link:hover {
  color: black;
} */
.sidebar:global.nav.highlight-bg .nav-link.active {
  background-color: var(--bs-primary);
  /* color: var(--bs-contrast-primary); */
  color:white;
  border-top-left-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}

.sidebar:global.nav .h5 {
  color: var(--bs-primary) !important;
  border-bottom-color: var(--bs-primary) !important;
  padding: 0.75rem 0.5rem;
}
.sidebar:global.nav .h5:not(:first-child) {
  margin-top: 1rem;
}

/* Mobile Menu */
.sidebar:global.highlight-underline .nav-link.active {
  text-decoration: underline;
  font-weight: 900;
}
/* .sidebar:global.highlight-underline .nav-link.active i { */
/*   color: var(--accent); */
/* } */
.sidebar:global.variant-dark.highlight-underline .nav-link,
.sidebar:global.variant-dark.highlight-underline .nav-link.active {
  color: var(--primary);
}

.home_button {
  width: 9.5rem;
  height: 9.5rem;
  display: flex;
  flex-direction: column;
  background-color: var(--bs-primary);
  color: var(--bs-contrast-primary);
  border-radius: var(--bs-border-radius);
  padding: 1rem 0.5rem;
  margin: 1.25rem;
}

.home_button,
.home_button:hover,
.home_button:focus {
  box-shadow: 0.25rem 0.25rem 0.25rem #00000055 !important;
}
.home_button div {
  text-align: center;
}
.home_button div:first-child {
  font-size: 3rem;
}

@media (max-width: 1080px) {
  .home_button {
    width: 8rem;
    height: 7.9rem;
    padding: 0.5rem;
    margin: 0.65rem;
  }
  .home_button div:first-child {
    font-size: 2.5rem;
  }
  .home_button div:last-child {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .home_button_break {
    display: none !important;
  }
}
