.bg_gray {
  background-color: var(--bs-gray-300);
  cursor: pointer;
}

.custom_dropzone {
  border: dashed 2px #c8c8c8 !important;
  border-radius: var(--bs-border-radius);
  padding: 0.35rem;
  flex: 1;
  width: 100%;
  background-color: white;
}

.card_progressing {
  border: none !important;
  align-items: center;
  box-shadow: unset;
  background-color: var(--bs-gray-300);
  text-decoration: none !important;
}
