/* lato-100 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: local(''), url('/public/fonts/lato-v20-latin-100.woff2') format('woff2');
}

/* lato-100italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100;
  src: local(''), url('/public/fonts/lato-v20-latin-100italic.woff2') format('woff2');
}

/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local(''), url('/public/fonts/lato-v20-latin-300.woff2') format('woff2');
}

/* lato-300italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: local(''), url('/public/fonts/lato-v20-latin-300italic.woff2') format('woff2');
}

/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('/public/fonts/lato-v20-latin-regular.woff2') format('woff2');
}

/* lato-italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: local(''), url('/public/fonts/lato-v20-latin-italic.woff2') format('woff2');
}

/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('/public/fonts/lato-v20-latin-700.woff2') format('woff2');
}

/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: local(''), url('/public/fonts/lato-v20-latin-900.woff2') format('woff2');
}

/* lato-700italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: local(''), url('/public/fonts/lato-v20-latin-700italic.woff2') format('woff2');
}

/* lato-900italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900;
  src: local(''), url('/public/fonts/lato-v20-latin-900italic.woff2') format('woff2');
}
