/*FADEBAR*/
.icon-menu .list-group-item,
.fade-menu .list-group-item {
  border: unset !important;
}

.icon-menu i {
  font-size: 1.3rem;
}
.container-fade-menu {
  margin-left: 3rem;
  position: absolute !important;
  height: 94vh;
}
.fade-menu {
  background-color: white;
  height: 94vh;
}

/*TOP NAVBAR*/
.btn-hamburger {
  border-radius: unset !important;
  background-color: var(--bs-primary);
  border-color: unset !important;
}

.list-group a {
  text-decoration: unset;
}


#user-dropdown-menu {
  right: 0 !important;
  left: auto !important;
  margin-top: 0.5rem;
}

#user-dropdown-home > button.dropdown-toggle::after {
  display: none !important;
}
#settings-dropdown-menu {
  right: 0 !important;
  left: auto !important;
  margin-top: 0.5rem;
}

#settings-dropdown-home > button.dropdown-toggle::after {
  display: none !important;
}
