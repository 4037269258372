// change the theme
// $theme-colors: (
//   "primary": #3c9c99,
// );

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Lato', 'Open Sans', sans-serif;
  font-size: 14px;
}

* {
  box-sizing: border-box;
}

#root{
  display: contents;
}

html {
  height: 100%;
  scroll-behavior: smooth;
  --hr-shadow: 0 0.25rem 1rem rgba(var(--bs-black-rgb), 0.15);
}

body {
  // background: var(--bs-gray-100);
  // background-color: #e5ecf4;
  height: 100%;
  display: flex;
  flex-direction: column;
}

main.layout {
  flex: 1;
  display: flex;
  flex-direction: column;
}

footer.layout {
  /* background: var(--bs-gray-200); */
  font-size: 80%;
}

.card {
  box-shadow: var(--hr-shadow);
}

.list-group {
  gap: 0.5rem;
}
.list-group-item {
  box-shadow: var(--hr-shadow);
}
.list-group-item + .list-group-item {
  border-top-width: 1px;
}

.fas,
.fab,
.far {
  width: 1.5em !important;
}

.fas-nowidth,
.fab-nowidth,
.far-nowidth {
  width: unset !important;
}

.notification-container--top-right {
  top: 5.65rem;
  right: 1rem;
}
.notification__item--success {
  background-color: var(--bs-success);
  border-left: none;
}
.notification__item--danger {
  background-color: var(--bs-danger);
  border-left: none;
}

/**
 * react-datepicker component
 */
.react-datepicker__close-icon::after,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text.react-datepicker__month--selected,
.react-datepicker__month-text.react-datepicker__month--in-range,
.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__month--in-range:hover,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover {
  background-color: var(--bs-primary) !important;
}

.react-datepicker__time-list-item--selected {
  background-color: var(--bs-primary) !important;
}

.react-datepicker-year-header {
  padding-bottom: 0.5rem !important;
}
.react-datepicker__input-container > input {
  line-height: 1.6 !important;
}

@media screen and (min-width: 1720px) {
  .d-xxxl-none {
    display: none !important;
  }
  .d-xxxl-block {
    display: block !important;
  }
}

.w-65 {
  width: 65% !important;
}
.w-90 {
  width: 90% !important;
}

.form-control-md {
  min-height: calc(1.9em + 0.5rem + 2px);
  font-size: 0.9rem;
}

/**
 * react-dual-listbox component
 */
.react-dual-listbox .rdl-filter,
.react-dual-listbox .rdl-control {
  border-radius: 0.15rem !important;
  padding: 0.375rem 0.75rem !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: unset !important;
  color: black !important;
}

/**
* Generic components
*/
.block-title {
  margin-bottom: 0;
  padding: 0.5rem 1rem;
  background-color: var(--primary-100);
  color: var(--bs-primary);
  border-color: var(--primary-100);
  border-bottom: solid 0.15rem var(--bs-primary) !important;
}
.with-border {
  border: solid 1px #dee2e6;
}
.with-shadow {
  /* box-shadow: 0.25rem 0.25rem 0.25rem var(--bs-gray-400); */
  box-shadow: 0 1px 4px #00000033;
}

.breadcrumb {
  border-radius: var(--bs-border-radius);
  background-color: var(--bs-light);
}

.modal-backdrop.show {
  z-index: 1055;
}

/**
* Shifts print styles
*/
.shift_title {
  display: none;
}


.rce-container-mbox {
  min-width: unset !important;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

/* FIX REGRESSION ON BOOTSTRAP 5.2+ */
.tooltip {
  position: absolute;
}

.card{
  border-radius:0 !important;
}

.form-control{
  border-radius:0 !important;
}

.fs-15{
  font-size: 1.5rem;
}
.fs-14{
  font-size: 1.4rem;
}
.fs-16{
  font-size: 1rem;
}

.fs-18{
  font-size: 1.13rem;
}

.fs-20{
  font-size: 2rem;
}
