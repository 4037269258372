
.offcanvas_bg {
  background-color: #fbfbfb;
  background-image: url("../../../public/texture-card-1.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax {
  height: 26vh;
  min-height: 191px;
  background-attachment: scroll;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.product_card {
  padding: 0;
  background-color: white;
  -webkit-box-shadow: 0px 9px 6px -8px #00000066;
  box-shadow: 0px 9px 6px -8px #00000066;
  background-image: url("../../../public/texture-card-1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px;
  border-radius: 4px;
}

.product_image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #ffffff40;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.description_ingredients {
  color: black;
  font-size: 14px;
  font-weight: 400;
  min-height: 75px;
  overflow-y: auto;
}
.divider_2 {
  border-bottom: 2px solid #fff;
  background-color: #e2e2e2;
  height: 3px;
  margin-bottom: 2px;
}

.divider_2 span {
  display: block;
  width: 50px;
  height: 3px;
  background-color: rgb(0, 0, 0);
}

.leaders {
  max-width: 40em;
  padding: 0;
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  list-style: none;
  border-bottom: 2px solid;
}
.leaders li:before {
  float: left;
  width: 0;
  white-space: nowrap;
}
.leaders span:first-child {
  padding-right: 0.33em;
  background: rgba(255, 255, 255, 0);
}
.leaders span + span {
  float: right;
  padding-left: 0.33em;
  background: rgba(255, 255, 255, 0);
}

.icon_zoom_image {
  position: absolute;
  bottom: 0;
  left: calc(var(--bs-gutter-x) * .5);;
  padding: 7px;
  font-size: 18px;
}

.line_clamp{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.5rem;
  height: 3.2rem;
}